@import "./../font/colorful-font/iconfont.css";
@import "./../font/normal-font/iconfont.css";
// @font-face {
//     font-family: 'iconfont';
//     /* Project id 3871291 */
//     src: url('//at.alicdn.com/t/c/font_3871291_h6ijmevs656.woff2?t=1677744328320') format('woff2'),
//         url('//at.alicdn.com/t/c/font_3871291_h6ijmevs656.woff?t=1677744328320') format('woff'),
//         url('//at.alicdn.com/t/c/font_3871291_h6ijmevs656.ttf?t=1677744328320') format('truetype');
// }

//at.alicdn.com/t/c/font_3868575_bnrhjdu73yd.css

//times_new_roman
// @font-face {
//     font-family: 'TimesNewRoman';
//     src: url('./../font/TimesNewRoman/TimesNewRoman.ttf');
// }

// //雅黑
// @font-face {
//     font-family: 'WeiRuanYaHei';
//     src: url('./../font/YaHei/YaHei.ttf');
// }

// //宋体
// @font-face {
//     font-family: 'SourceHanSerif';
//     src: url('./../font/SourceHanSerif/Source_Han_Serif_SC_Light_Light.otf');
// }

// //黑体
// @font-face {
//     font-family: 'SourceHanSansRegular';
//     src: url('./../font/SourceHanSansRegular/Source_Han_Sans_SC_Regular.otf');
// }

// //黑体
// @font-face {
//     font-family: 'OppoHanSansRegular';
//     src: url('./../font/OPPOHanSansRegular/OPPOSans-R.ttf');
// }

// //雅黑
// @font-face {
//     font-family: 'WeiRuanYaHei';
//     src: url('http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303144896e1c4e8-2585-4bd6-9fe4-1ec94cfdd09811.ttf');
// }

// //宋体
// @font-face {
//     font-family: 'SourceHanSerif';
//     src: url('http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303143698207af1-17c1-40cd-b2c1-a25acf66d76639.otf');
// }

// //黑体
// @font-face {
//     font-family: 'SourceHanSansRegular';
//     src: url('http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/202303140025c0a776-8f46-4422-a5e8-8cf49ab6e18964.otf');
// }

// @font-face {
//     font-family: 'OppoHanSansRegular';
//     src: url('http://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/public/2023031412679eb176-25fc-49dc-9b14-d2a5bcf7b1aa43.ttf');
// }

div,
p,
span {
  box-sizing: border-box;
  margin: 0;
  // font-family: 'OppoHanSansRegular';
  // font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 400;
}

.app-outer-container {
  width: 100%;
  height: 100vh;
  // background-color: red;
  // min-width: 1200px;
  overflow: hidden;
  font-weight: 400;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.edit-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f5f7f9;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 1400px;
  overflow-x: auto;
  box-sizing: border-box;
}

.flex-row {
  display: -webkit-box;
  /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box;
  /* Firefox 17- */
  display: -webkit-flex;
  /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex;
  /* Firefox 18+ */
  display: -ms-flexbox;
  /* IE 10 */
  display: flex;
  /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-col {
  display: -webkit-box;
  /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box;
  /* Firefox 17- */
  display: -webkit-flex;
  /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex;
  /* Firefox 18+ */
  display: -ms-flexbox;
  /* IE 10 */
  display: flex;
  /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.edit-page-header {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
}

//word-edit-area-container content-border content-border-radius
.edit-page-content {
  height: calc(100% - 156px);
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.edit-page-content-right {
  width: 376px;
  height: 100%;
  background-color: #fff;
  flex-shrink: 0;
}

.content-border {
  border: 1px solid #f0f0f0;
}

.content-border-radius {
  border-radius: 3px;
}

.edit-page-option-row {
  width: 100%;
  height: 80px;
  background-color: #fff;
  margin-bottom: 12px;
  border-top: none !important;
  padding: 0 10px;
}

.edit-page-option-row-item {
  width: 128px;
  height: 100%;
  padding: 6px 0;
  flex-shrink: 0;
}

.edit-page-option-row-border {
  height: calc(100% - 24px);
  width: 1px;
  background-color: #e9e9e9;
  flex-shrink: 0;
}

.edit-page-option-row-half {
  width: 100%;
  height: 50%;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}

.trasition-ease {
  transition: all ease 0.25s;
}

.user-header-image-container {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-right: 18px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.custom-tool-tip-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .custom-tool-tip-title {
    font-size: 14px;
    color: #fff;
    margin: 0;
  }

  .custom-tool-tip-desc {
    font-size: 12px;
    color: #fff;
    margin: 0;
  }
}

.option-bar-select {
  .ant-select-arrow {
    margin-right: -6px;
    color: #7f7f7f;
  }
}

.text-edit-row-1 {
  width: 100%;
  // background-color: red;
  justify-content: space-between;
  align-items: center;
}

.custom-popover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  .custom-popover {
    position: absolute;
    top: 96px;
    left: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.062);
    border: 1px solid #e7e9e8;
    transition: all ease 0.2s;
    transform: scaleY(0.6);
    transition-delay: 0ms;
    transform-origin: top;
    transition-duration: 200ms;
    opacity: 0;
    z-index: -1;
    font-feature-settings: "tnum";
    font-variant: "tabular-nums";
    overflow: hidden;
  }

  .custom-popover.show {
    transform: scaleY(1);
    opacity: 1;
    z-index: 99999;
    box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.062);
  }
}

.editor-option-select {
  position: relative;
  min-width: 32px;
  height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 12px;
  padding-right: 24px;
  cursor: pointer;
  transition: all ease 0.25s;
  box-sizing: border-box;

  .editor-option-select-value {
    width: 100%;
    font-size: 12px;
    color: #000;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .editor-option-select-placeholder {
    font-size: 12px;
    color: #909399;
    margin: 0;
    padding: 0;
  }

  .editor-option-select-arrow {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    z-index: 999;
    padding-left: 4px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #999;
    pointer-events: none;
  }
}

.editor-option-select:hover {
  border: 1px solid #1890ff;
}

.custom-select-container {
  width: 100%;
  padding: 4px 0;
  min-width: 68px;
  flex-shrink: 0;
  box-sizing: border-box;

  .custom-select-item {
    width: calc(100% - 8px);
    height: 36px;
    transition: all ease 0.25s;
    padding: 0 12px;
    margin: 0 4px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-select-item:hover {
    background-color: #f5f5f5;
  }

  .custom-select-item.active {
    background-color: #e6f4ff;
  }
}

.edit-page-header-side {
  width: 320px;
  height: 100%;
}

.edit-page-header-left {
  justify-content: flex-start;
  align-items: center;
}

.edit-page-header-right {
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
}

.edit-page-back-btn {
  width: 32px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-right: 6px;
  padding-left: 3px;
  cursor: pointer;

  i {
    font-size: 18px;
    color: #141414;
  }
}

.edit-page-back-btn:hover {
  background-color: #f2f2f2;
}

.project-info {
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;

  .project-name {
    font-size: 16px;
    color: #141414;
    font-weight: 900;
  }

  .project-info-bottom {
    width: 100%;
    height: 28px;
    justify-content: flex-start;
    align-items: center;

    .project-info-text {
      font-size: 12px;
      color: #8c8c8c;
    }

    .project-info-border {
      width: 1px;
      height: 12px;
      background-color: #d9d9d9;
      margin: 0 12px;
    }

    .project-info-border-transform {
      width: 1px;
      height: 12px;
      background-color: #d9d9d9;
      margin: 0 8px;
      transform: rotateZ(15deg);
    }

    .project-info-status {
      justify-content: flex-start;
      align-items: center;
    }

    .project-info-status-text {
      font-size: 12px;
      color: #8c8c8c;
      margin-left: 5px;
    }
  }
}

.ant-btn-primary:disabled {
  background-color: #91caff;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.login-right-input {
  .ant-input-prefix {
    margin-inline-end: 8px;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.main-container {
  width: 100%;
  height: calc(100vh - 80px);
  padding: 12px 15px;
  padding-top: 18px;
  padding-bottom: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  // background-color: green;
  .main-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 42px;
    box-sizing: border-box;
    padding-bottom: 12px;
    padding-left: 3px;
    padding-right: 5px;
    .main-header-box-left {
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      flex: 1;
    }

    .main-header-box-right {
      height: 100%;
      min-height: 42px;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .main-table-box {
    height: calc(100% - 80px);
    width: 100%;
    overflow-x: hidden;
  }

  .main-panel-footer {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    justify-content: center;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
}

.main-header-box-option-el {
  margin-bottom: 12px;
}

.main-header-box-search-input {
  width: 120px;
  margin-bottom: 12px;
}

.user-portrait-container {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-drag: none;
  .user-portrait {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.user-portrait-container.active {
  border: 1px solid #1890ff;
}

.custom-modal-container {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
}

.custom-modal-main-form {
  width: 100%;
}

.table-expand-icon {
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 14px;
  color: #595959;
  cursor: pointer;
  transition: all ease 0.25s;
  transform-origin: center !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.custom-side-menu {
  font-weight: 400 !important;
  user-select: none;

  .ant-menu-submenu.ant-menu-submenu-inline {
    font-weight: 400 !important;

    .ant-menu-submenu-title {
      .ant-menu-title-content {
        font-size: 16px;
        font-weight: 400 !important;
      }

      .ant-menu-item-icon {
        font-size: 18px;
        margin-left: -5px;
      }
    }

    .ant-menu-item.ant-menu-item-only-child {
      font-size: 15px !important;
    }
  }

  .ant-menu-item {
    .ant-menu-item-icon {
      font-size: 18px;
      margin-left: -5px;
    }

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 400 !important;
    }
  }
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.form-item-wrapper {
  width: 100%;
  transition: all ease-in-out 0.25s;
  overflow: hidden;
  padding: 0;
}

.table-inline-avatar-container {
  position: relative;
  width: 52px;
  height: 52px;
  .table-inline-avatar-tag {
    position: absolute;
    top: 0;
    right: -44px;
    z-index: 999;
    // background-color: rgba(0,0,0,0.32);
    // border: 1px solid rgba(0,0,0,0.32);
    border-radius: 3px;
    // font-size: 10px;
    color: #fff;
  }
}

.table-inline-avatar {
  width: 52px;
  height: 52px;
  overflow: hidden;
  border-radius: 50%;
}

.ant-table-body {
  overflow: scroll !important;
}

.ant-table-content {
  overflow: scroll !important;
}

.custom-table-middle {
  width: 100%;
  border-color: #000 !important;
  font-size: 15px;

  .rs-table-header-row-wrapper {
    font-size: 14px;
    color: #262626;
    font-weight: bold !important;

    .rs-table-row.rs-table-row-header {
      .rs-table-cell-group.rs-table-cell-group-scroll {
        .rs-table-cell-header {
          .rs-table-cell {
            background-color: #f6f7f8 !important;
            border-color: #000 !important;

            .rs-table-cell-content {
              font-weight: 700 !important;
              line-height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;

              .rs-table-column-group {
                .rs-table-column-group-header {
                  border-color: #000;
                  font-size: 14px;
                  color: #262626;
                  font-weight: bold !important;

                  .rs-table-column-group-header-content {
                    font-size: 14px;
                    color: #262626;
                    font-weight: bold !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }

                .rs-table-column-group-cell.rs-table-cell-header {
                  .rs-table-cell {
                    .rs-table-cell-content {
                      .rs-table-column-group-cell-content {
                        font-size: 14px;
                        color: #262626;
                        font-weight: bold !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .rs-table-body-row-wrapper {
    .rs-table-body-wheel-area {
      .rs-table-row {
        .rs-table-cell-group {
          .rs-table-cell {
            border-color: #000;
          }
        }
      }
    }
  }
}

.ant-table-body {
  overflow: scroll !important;
}

.ant-table-body::-webkit-scrollbar {
  /* 隐藏默认的滚动条 */
  -webkit-appearance: none !important;
}

.ant-table-body::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 2px;
}

.ant-table-body::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 8px !important;
  border-radius: 3px !important;
  padding: 0 3px !important;
  box-sizing: border-box !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 3px;
  border: 2px solid #d9d9d9;
  background-color: #d9d9d9;
  cursor: pointer;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  // box-shadow: inset 3px 3px 3px #d9d9d9, inset -3px -3px 3px #d9d9d9;
}

.single-line-text {
  width: auto;
  word-break: break-all;
  text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
  word-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
}

.mutiple-line-text-2 {
  width: 260px;
  display: -webkit-box; //必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。
  word-break: break-all;
  text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mutiple-line-text-3 {
  width: 260px;
  display: -webkit-box; //必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。
  word-break: break-all;
  text-overflow: ellipsis; //可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.custom-tree-node {
  width: 100%;
  flex: 1;
  display: inline-block;
  // background-color: red;
}

.custom-context-menu {
  position: fixed;
  top: 100px;
  left: 200px;
  width: 200px;
  // height: 360px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.062);
  border: 1px solid #e7e9e8;
  // transition: all ease 0.2s;
  transform: scaleY(0.6);
  transition-delay: 0ms;
  transform-origin: top;
  // transition-duration: 200ms;
  opacity: 0;
  z-index: 999;
  font-feature-settings: "tnum";
  font-variant: "tabular-nums";
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 0;
  box-sizing: border-box;

  .custom-context-menu-item {
    height: 36px;
    width: calc(100% - 12px);
    margin: 3px 6px;
    border-radius: 8px;
    font-size: 14px;
    color: #262626;
    cursor: pointer;
    transition: all ease 0.2s;
    padding: 0 9px;
  }

  .custom-context-menu-item:hover {
    background-color: #f8f8f8;
  }

  .custom-context-menu-border {
    width: calc(100% - 12px);
    margin: 0 auto;
    height: 1px;
    border-bottom: 1px solid #eee;
    margin-top: 5px;
    margin-bottom: 6px;
  }
}

.custom-context-menu.show {
  transform: scaleY(1);
  opacity: 1;
  z-index: 99999;
  box-shadow: 0 8px 16px 4px rgba(0, 0, 0, 0.062);
}

.ant-tree.ant-tree-block-node.custom-tree {
  .ant-tree-list {
    .ant-tree-list-holder {
      div {
        .ant-tree-list-holder-inner {
          .ant-tree-treenode {
            .ant-tree-node-content-wrapper {
              display: flex;

              .ant-tree-title {
                flex: 1;
                display: flex;
                flex-direction: row;
              }
            }
          }
        }
      }
    }
  }
}

.inline-topic-table-conatiner {
  width: 100%;
  height: 100%;

  .inline-topic-table-content {
    width: 100%;
    height: calc(100vh - 275px);
    overflow: hidden;
    flex-shrink: 0;
    // background-color: green;
  }

  .inline-topic-table-footer {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    justify-content: center;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    // background-color: blue;
  }
}

.main-sub-container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 0 12px;
  background-color: #fff;
  .main-sub-container-header {
    width: 100%;
    height: 54px;

    // border-bottom: 1px solid #eee;
    // margin-bottom: 12px;
    .main-sub-container-header-left {
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      padding-left: 3px;

      .main-sub-container-header-title {
        font-size: 15px;
        color: #262626;
      }
    }
  }

  .main-sub-content {
    width: 100%;
    height: calc(100% - 64px);
  }
}

.shadow-2-down {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0 rgba(0, 0, 0, 0.18), 0px 9px 28px 8px rgba(0, 0, 0, 0.15);
}

.dropdown-button-group {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.06),
    0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 12px 48px 16px rgba(0, 0, 0, 0.03);
  border: 1px solid #eee;
}

.custom-border {
  height: 1px;
  border-bottom: 1px solid #eee;
  margin-top: 1px;
  margin-bottom: 1px;
}

.disable-overlay {
  position: relative;
  opacity: 0.52;
  transition: all ease 0.25s;
}

.disable-touch-overlay {
  position: relative;
  opacity: 0.52;
  pointer-events: none;
  transition: all ease 0.25s;
}

.my-transition {
  transition: all ease 0.25s;
  transition-delay: 0;
}

.diable-without-effect {
  background-color: transparent !important;
  color: #262626 !important;
  opacity: 0.72;
}

// .disable-overlay::before {
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 999;
//     width: 100%;
//     height: 100%;
//     background-color: red;
// }

// ::-webkit-scrollbar {
//     height: 5px;
//     width: 5px;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     background: #4a5c7b;
// }

// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: 0;
//     border-radius: 0;
//     background: #191f28;
// }

.card-empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fast-insert-property-tools {
  position: absolute;
  top: 49px;
  left: 78px;
  width: 96px;
  height: 200px;
  padding-top: 9px;
  // background-color: #f2f2f2;
}

.my-link {
  font-size: 14px;
  color: #1890ff;
  margin-top: 3px;
  cursor: pointer;
}

.my-link:hover {
  text-decoration-line: underline;
}

.ant-pagination {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .ant-pagination-item {
    height: 26px;
    min-width: 26px;
    line-height: 26px;
    border-radius: 5px;
    line-height: 24px;
    padding-right: 1px;
  }
  .ant-pagination-options {
    .ant-select.ant-pagination-options-size-changer {
      .ant-select-selector {
        height: 26px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-pagination-options-quick-jumper {
      height: 26px !important;
      line-height: 26px;
      padding-left: 3px;
      input {
        height: 26px !important;
      }
    }
  }
}

.empty-wapper-container {
  width: 100%;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loading-wapper-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  flex: 1;
  height: 100%;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  // background-color: red!important;
  background-color: rgba(255, 255, 255, 0.72);
  transition: all ease 0.25s;

  .loading-title {
    font-size: 14px;
    color: #7f7f7f;
    margin-top: 12px;
  }
}

.loading-wapper-container.show {
  background-color: rgba(255, 255, 255, 0.72);
  left: 0;
  top: 0;
  width: 100%;
  flex: 1;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-wapper-container.hide {
  background-color: rgba(255, 255, 255, 0) !important;
  pointer-events: none;
  z-index: -999;
}

.loading-wapper-container.hide-overlay {
  background-color: transparent !important;
}

.paid-package-item {
  width: 320px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-right: 18px;
  margin-bottom: 18px;
}
