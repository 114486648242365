@font-face {
  font-family: "iconfont"; /* Project id 3868575 */
  /* Color fonts */
  src: 
       url('iconfont.woff2?t=1677839409491') format('woff2'),
       url('iconfont.woff?t=1677839409491') format('woff'),
       url('iconfont.ttf?t=1677839409491') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ghzs-color-fenzuwenjian:before {
  content: "\e68e";
}

.ghzs-color-tuichudenglu:before {
  content: "\e68f";
}

.ghzs-color-shanchu:before {
  content: "\e690";
}

.ghzs-color-tishi:before {
  content: "\e67c";
}

.ghzs-color-baohuqu:before {
  content: "\e67d";
}

.ghzs-color-yeshukuang:before {
  content: "\e67e";
}

.ghzs-color-fenzhi:before {
  content: "\e67f";
}

.ghzs-color-logo:before {
  content: "\e678";
}

.ghzs-color-quzhuce:before {
  content: "\e679";
}

.ghzs-color-lengdongchuangge:before {
  content: "\e66a";
}

.ghzs-color-hebingjuzhong:before {
  content: "\e66b";
}

.ghzs-color-zitidiwen:before {
  content: "\e669";
}

.ghzs-color-beijingtianchong:before {
  content: "\e66d";
}

.ghzs-color-beijingse1:before {
  content: "\e674";
}

.ghzs-color-zitise1:before {
  content: "\e675";
}

.ghzs-color-xiahuaxian1:before {
  content: "\e676";
}

.ghzs-color-shanchuxian1:before {
  content: "\e677";
}

.ghzs-color-jia:before {
  content: "\e65d";
}

.ghzs-color-jian:before {
  content: "\e65e";
}

.ghzs-color-shangbiao:before {
  content: "\e66e";
}

.ghzs-color-xiabiao:before {
  content: "\e671";
}

.ghzs-color-chazhao:before {
  content: "\e66c";
}

.ghzs-color-wuxubiaoge:before {
  content: "\e65b";
}

.ghzs-color-fensanduiqi:before {
  content: "\e651";
}

.ghzs-color-jianshaotuijin:before {
  content: "\e652";
}

.ghzs-color-zengjiatuijin:before {
  content: "\e664";
}

.ghzs-color-youxubiaoge:before {
  content: "\e666";
}

.ghzs-color-hanggao:before {
  content: "\e668";
}

.ghzs-color-charu:before {
  content: "\e64e";
}

